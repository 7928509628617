import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import Toast from "./components/Toast";


const root = ReactDOM.createRoot(document.getElementById("root"));

const REACT_APP_ENV = "development";

if (REACT_APP_ENV === "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}
root.render(
  <React.StrictMode>
    <Toast/>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();